<template>
    <div>
        <section>
    <div class="container-lg">
      <div class="row">
        <div class="d-flex col-auto">
            <router-link :to="{ name: 'Home' }" class="btn btn-outline-primary border-0 mb-3 mt-3"><i class="bi bi-arrow-left"></i> Back to home</router-link>
          </div>
          <h2 class="display-6">Account</h2>
          <h3>Welcome, {{ userInfo.document.fullname }}</h3>
          <p>Manage your info, license assignment, and admin domain access.</p>
      </div>
    </div>
  </section>
  <section id="createDemo" class="mt-5 bg-light">
    <div class="container-lg">
        <div class="col-lg-6">
            <h5>Profile</h5>
            <table class="table table-hover ">
                            
                            <tbody>
                                <tr>
                                    <td>Full Name</td>
                                    <td>{{ userInfo.document.fullname }}</td>
                                </tr>
                                 <tr>
                                    <td>Company</td>
                                    <td>{{ userInfo.document.company }}</td>
                                </tr>
                                 <tr>
                                    <td>Partner Email</td>
                                    <td>{{ userInfo.document.partnerEmail }}</td>
                                </tr>
                                <tr>
                                    <td>Date Registered</td>
                                    <td>{{ userInfo.document.creationTime.toDate() }}</td>
                                </tr>
                                 <tr>
                                    <td>Email</td>
                                    <td>{{ userInfo.document.email }}</td>
                                </tr>
                            </tbody>
                        </table>
        </div>
        
    </div>
  </section>
  <section>
    
    <div class="container-lg">
        <h5>Licenses</h5>
          <p>You can manage your licenses below. Use the toggles to enable or disable them.<br>Note: Gemini licenses are temporary and expire after 24 hours.</p>
          <!-- <div class="form-check form-switch ms-2" >
            <input class="form-check-input" type="checkbox" id="educationplus" v-model="licenses.eduplus">
            <label class="form-check-label ms-2" for="users">Education Plus</label>
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Enable this option to assign an Education Plus (Staff) license.">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            </div> -->
            <div class="form-check form-switch ms-2" >
            <input class="form-check-input" type="checkbox" id="gemini" v-model="userInfo.document.duetAI" @change="updateGemini()">
            <label class="form-check-label ms-2" for="users">Gemini Education Premium</label>
            <!-- tooltip -->
                <span class="tt ms-2" data-bs-placement="bottom" data-bs-toggle="tooltip" title="Enable this to assign a Gemini Education Premium license (maximum 24 hours).">
                  <i class="bi bi-info-circle-fill text-muted"></i>
              </span>
            </div>
        </div>
  </section>
  <section id="createDemo" class="mt-5 bg-light">
    <div class="container-lg">
    <h5>Security domain admin access</h5>
    <div class="col-lg-8">
        <div v-if="userInfo.document.adminUsername">
            <div class="input-group mb-4">

    <span class="input-group-text">
        <strong>Username</strong>
    </span>
    <input type="text" class="form-control" v-model="userInfo.document.adminUsername" @focus="() => copyToClipboard(userInfo.document.adminUsername)" readonly>
    <!-- tooltip -->
    <span class="input-group-text" @click="() => copyToClipboard(userInfo.document.adminUsername)">
        
        <i :class="iconState === 'clipboard' ? 'bi bi-clipboard' : 'bi bi-clipboard-check'" ></i>
        
    </span>
</div>
<div class="input-group mb-4">
    <span class="input-group-text">
        <strong>Password</strong>
    </span>
    <input type="text" class="form-control" v-model="userInfo.document.adminPassword" @focus="() => copyToClipboard2(userInfo.document.adminPassword)" readonly>
    <!-- tooltip -->
    <span class="input-group-text" @click="() => copyToClipboard2(userInfo.document.adminPassword)">
        
        <i :class="iconState2 === 'clipboard' ? 'bi bi-clipboard' : 'bi bi-clipboard-check'"></i>
        
    </span>
</div>
<p><strong>Logging In:</strong></p>

<ol>
    <li>Navigate to the <a href="https://admin.google.com" target="_blank">Google Workspace Admin Console</a>.</li>
    <li>Use the above username and password to sign in.</li>
</ol>
<p><strong>Special Note:</strong></p>
<p>Your demo account has been assigned an <strong>Admin Role</strong>. This role grants you access to demonstrate advanced security features within Google Workspace, such as the Security Dashboard and Investigation Tool.</p>
        </div>
        <div v-else>
            <p>To demo Education Plus security features like the Security Dashboard and Investigation Tool, use the button below to generate Admin Console login details.</p>
            <button type="button" class="btn btn-secondary border-0 ms-3" @click="createAdmin()">
                    <i class="bi bi-arrow-clockwise"></i> Create security domain admin user
                    </button>
        </div>
    
    </div>

</div>
    </section>
    </div>
    
  </template>
  
  <script>
  import axios from "axios";
  import translate from '@/composables/translate'
  import { useStore } from 'vuex'
  import { computed, ref } from '@vue/reactivity'
  import getUser from '@/composables/getUser'
  import getDoc from '@/composables/getDoc'
  import { Tooltip } from "bootstrap";
  export default {
    setup(){
      const { user,userInfo } = getUser()
      const store = useStore()
      const lang = computed(() => store.state.lang)
      const { getLanguage, translated, forms } = translate()
      getLanguage('help',lang)

      const licenses = {
        eduplus: true,
        gemini: false
      }

      const form = {
        language: 'English'
      }
  
      const languages = ref([])
        axios.get('/data/locale.json')
        .then((res) => {
        languages.value = res.data.languages
        })

      function createAdmin() {
            axios.get('https://us-central1-demotool-e1a5e.cloudfunctions.net/createSecurityAdmin?docId='+userInfo.value.document.id)
            }


            async function updateGemini() {
            axios.get('https://us-central1-demotool-e1a5e.cloudfunctions.net/enableDuetAI?email='+userInfo.value.document.email+'&userDoc='+userInfo.value.document.id)
            await setDoc(doc(db, 'users', sessionId), {
            userLicense: skuId
          },{ merge: true });
            }

            const iconState = ref('clipboard'); // initial state is 'clipboard'
            const iconState2 = ref('clipboard');

            function copyToClipboard(value) {
            // Use the passed value for the clipboard operation
            navigator.clipboard.writeText(value);

            iconState.value = 'tick';

            setTimeout(() => {
                iconState.value = 'clipboard';
            }, 2000);
        }
        function copyToClipboard2(value) {
            // Use the passed value for the clipboard operation
            navigator.clipboard.writeText(value);

            iconState2.value = 'tick';

            setTimeout(() => {
                iconState2.value = 'clipboard';
            }, 2000);
        }
  
      return { translated, copyToClipboard, copyToClipboard2, iconState, iconState2, userInfo, user, createAdmin, licenses, languages, updateGemini }
        
    },
    mounted() {
    const tooltips = document.querySelectorAll('.tt')
    tooltips.forEach(t => {
      new Tooltip(t)
    })
  },
  updated(){
    const tooltips = document.querySelectorAll('.tt')
    tooltips.forEach(t => {
      new Tooltip(t)
    })
  }
  
  }
  
  </script>
  
  <style>
  
  </style>